import React from 'react';
import { LayoutProvider, Wrapper } from '../components/layout';
import {
  CannonQuality,
  LifeStyleBanner,
  ProductBanner,
  ProductFeatures,
  ProductLanding,
  ProductPunchline,
  ProductReviews,
  ProductShowcase,
  ProductSpecs,
} from '../components/sections';
import { ICannonSafe } from '../pages/index';

import { CannonBreadcrumbs } from '../atoms';
import { useAPIURL, useTableOrMobile } from '../hooks';
import IPage from '../@types/IPage';
import { cannonStyled } from '../theme';

const ContentContainer = cannonStyled('div', {
  position: 'relative',
  width: '100%',
});

const ProductDetailPage = (
  props: IPage<{
    safe: ICannonSafe;
    related: { edges: Array<{ node: ICannonSafe }> };
  }>
) => {
  const data = props.pageContext.safe;
  const items = props.pageContext.related.edges.filter(
    (safe) => safe.node.displayName !== data.displayName
  );
  const [isMobile, isTablet] = useTableOrMobile();
  const API_URL = useAPIURL();

  let titleExt = data.slug ? data.slug.split('-').join(' ') : '';


  return (
    <LayoutProvider
      seo
      locationSearch={props.location.search}
      titleExt={titleExt}
    >
      {!isMobile && (
        <CannonBreadcrumbs
          pages={[
            { title: 'Home', href: '/' },
            {
              title: data.safeCategory.title,
              href: `/safes/?type=${data.safeCategory.title}`,
            },
            {
              title: data.displayName,
              href: `/product/${data.slug}`,
            },
          ]}
        />
      )}
      <ContentContainer>
        <ProductLanding
          detailImages={data.detailImages || []}
          displayImage={data.displayImage}
          safeCategory={data.safeCategory.title}
          displayName={data.displayName}
          seriesTitle={data.series?.title}
          seriesDescription={data.description}
          averagePrice={data.averagePrice}
          reviews={data.reviews}
          upc={data.UPC}
          retailerCategory={data.retailerCategory}
          activeAction={data.activeAction}
          actionBanner={data.actionBanner}
          shopOnlineLink={data.shopOnlineLink}
          modelSKU={data.modelSKU}
          fileUrl={data.manual?.url}
        />
        <ProductBanner
          isMobile={isMobile}
          src={API_URL + data.coverImage.url}
        />
        <div id='specs' />
        <ProductSpecs
          isMobile={isMobile || isTablet}
          specifications={data.specifications.filter((spec) => !spec.main)}
          title={data.displayName}
          regulationParagraph={data.regulationParagraph}
          regulationActive={data.regulationActive}
        />
        <ProductFeatures
          specifications={data.specifications.filter((spec) => spec.main)}
        />
        <CannonQuality
          displayName={data.displayName}
          fileURL={data.manual?.url}
        />
        <LifeStyleBanner
          strapiLifestyleBanner={{
            title: data.lifestyleMedia.title,
            enabled: data.lifestyleMedia.enabled,
            description: data.lifestyleMedia.description,
            action: '',
            media: data.lifestyleMedia.media,
            videoLink: data.lifeStyleVideoLink || '',
            videoPreviewImage: data.lifeStyleVideoPreviewImage,
          }}
        />
        {items.length > 0 && (
          <ProductShowcase
            title='YOU MAY ALSO LIKE'
            description=''
            action='Buy Now'
            enabled
            list={items.map((li) => li.node)}
            showSubtitle
            showCaption
          />
        )}

        <ProductPunchline
          title={data.purchaseMedia.title}
          description={data.purchaseMedia.description}
          image={data.purchaseMedia.media}
        />
        <ProductReviews reviews={data.reviews} />
      </ContentContainer>
    </LayoutProvider>
  );
};

export default ProductDetailPage;
